
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import Layout from '../../templates/Blog';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = Layout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)}
             components={components}>

<MDXTag name="h1" components={components}>{`Hello world`}</MDXTag>
<MDXTag name="p" components={components}>{`Yes, I have pinned it down to the markdown compilation, it seems to randomly break during a compilation and then cannot proceed when the process restarts.`}</MDXTag>
<MDXTag name="p" components={components}>{`I think clearer documentation for the markdown structure needed would help too.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  